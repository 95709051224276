import axios from 'axios'
import { Link } from 'gatsby'
import { articleImageBlue } from 'images/hulp'
import React from 'react'
import Helmet from 'react-helmet'
import Loader from 'react-loader-spinner'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import NumberFormat from 'react-number-format'
import config from '../../../config'
import Layout from '../../../layouts'

const { appUrl, signupRoute } = config

class calculatorPage extends React.Component {
    state = {
        virgin: true,
        employee_type: 3,
        bruto_wage: 0,
        has_tax_reduction: true,
        ww_awf_type: 'hoog',
        hours: 40,
        bruto_hourwage: 0,
        employment_type: 'Fixed',
        hours_worked: 0,
        nettoWage: 0,
        tax: 0,
        zvw: 0,
        brutoWageResult: 0,
        isLoading: false,
        loonheffingPercentage: 0,
        nettoPercentage: 0,
        zvwPercentage: 0,
        taxableWage: 0,
        email: '',
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    constructor(props) {
        super(props)
    }

    handleSubmit = (event) => {
        event.preventDefault()

        this.setState({
            isLoading: true,
        })

        if (this.state.virgin) {
            {
                /*window.analytics.identify(this.state.email)*/
            }
            window.analytics.track('Used bruto-netto tool')

            this.setState({
                virgin: false,
            })

            axios
                .get(`https://api-prod.employes.nl/system/proforma`, {
                    params: {
                        employee_type: this.state.employee_type,
                        bruto_wage: this.state.bruto_wage,
                        has_tax_reduction: this.state.has_tax_reduction,
                        ww_awf_type: 'hoog',
                        hours: 40,
                        bruto_hourwage: this.state.bruto_hourwage,
                        employmentType: this.state.employment_type,
                        hours_worked: this.state.hours_worked,
                    },
                })
                .then((res) => {
                    var loonheffingPercentage =
                        (res.data.data.employeeCosts.tax /
                            res.data.data.employeeCosts.brutoWage) *
                        100
                    var zvwPercentage =
                        (res.data.data.employeeCosts.zvw /
                            res.data.data.employeeCosts.brutoWage) *
                        100
                    var nettoPercentage =
                        100 - (loonheffingPercentage + zvwPercentage)
                    var taxableWage = loonheffingPercentage + nettoPercentage

                    this.setState({
                        nettoWage: res.data.data.employeeCosts.nettoWage,
                        tax: res.data.data.employeeCosts.tax,
                        zvw: res.data.data.employeeCosts.zvw,
                        brutoWageResult: res.data.data.employeeCosts.brutoWage,
                        virgin: false,
                        isLoading: false,
                        loonheffingPercentage: loonheffingPercentage,
                        nettoPercentage: nettoPercentage,
                        zvwPercentage: zvwPercentage,
                        taxableWage: taxableWage,
                    })
                })
        } else {
            axios
                .get(`https://api-prod.employes.nl/system/proforma`, {
                    params: {
                        employee_type: this.state.employee_type,
                        bruto_wage: this.state.bruto_wage,
                        has_tax_reduction: this.state.has_tax_reduction,
                        ww_awf_type: 'hoog',
                        hours: 40,
                        bruto_hourwage: this.state.bruto_hourwage,
                        employmentType: this.state.employment_type,
                        hours_worked: this.state.hours_worked,
                    },
                })
                .then((res) => {
                    var loonheffingPercentage =
                        (res.data.data.employeeCosts.tax /
                            res.data.data.employeeCosts.brutoWage) *
                        100
                    var zvwPercentage =
                        (res.data.data.employeeCosts.zvw /
                            res.data.data.employeeCosts.brutoWage) *
                        100
                    var nettoPercentage =
                        100 - (loonheffingPercentage + zvwPercentage)
                    var taxableWage = loonheffingPercentage + nettoPercentage

                    this.setState({
                        nettoWage: res.data.data.employeeCosts.nettoWage,
                        tax: res.data.data.employeeCosts.tax,
                        zvw: res.data.data.employeeCosts.zvw,
                        brutoWageResult: res.data.data.employeeCosts.brutoWage,
                        virgin: false,
                        isLoading: false,
                        loonheffingPercentage: loonheffingPercentage,
                        nettoPercentage: nettoPercentage,
                        zvwPercentage: zvwPercentage,
                        taxableWage: taxableWage,
                    })
                })
        }
    }

    render() {
        return (
            <Layout location={this.props.location}>
                <main className="tools animated fadeInPage">
                    <Helmet>
                        <title>
                            Bruto-netto loon berekenen | Gratis HR Tool |
                            Employes
                        </title>
                        <meta
                            name="description"
                            content="Gratis omrekentool voor bruto-netto. Met deze handige tool bereken je in enkele klikken het nettoloon van een werknemer in Nederland."
                        />
                        <meta
                            itemprop="name"
                            content="Handige tools salarisadministratie | Employes"
                        />
                        <meta
                            itemprop="description"
                            content="Gratis omrekentool voor bruto-netto. Met deze handige tool bereken je in enkele klikken het nettoloon van een werknemer in Nederland."
                        />
                        <meta
                            itemprop="image"
                            content="/static/meta-bruto-netto.jpg"
                        />

                        {/* Windows tags */}
                        <meta
                            name="msapplication-TileColor"
                            content="#ffffff"
                        />
                        <meta
                            name="msapplication-TileImage"
                            content="static/favicon/mstile-144x144.png"
                        />

                        {/* OpenGraph tags */}
                        <meta property="og:url" content="" />
                        <meta property="og:type" content="website" />
                        <meta
                            property="og:title"
                            content="Bruto-netto loon berekenen | Gratis HR Tool | Employes"
                        />
                        <meta
                            property="og:description"
                            content="Gratis omrekentool voor bruto-netto. Met deze handige tool bereken je in enkele klikken het nettoloon van een werknemer in Nederland."
                        />
                        <meta
                            property="og:image"
                            content="https://employes.nl/static/meta-bruto-netto.jpg"
                        />

                        {/* Twitter Card tags */}
                        <meta
                            name="twitter:card"
                            content="summary_large_image"
                        />
                        <meta
                            name="twitter:title"
                            content="Bruto-netto loon berekenen | Gratis HR Tool | Employes"
                        />
                        <meta
                            name="twitter:description"
                            content="Gratis omrekentool voor bruto-netto. Met deze handige tool bereken je in enkele klikken het nettoloon van een werknemer in Nederland."
                        />
                        <meta
                            name="twitter:image"
                            content="https://employes.nl/static/meta-bruto-netto.jpg"
                        />
                    </Helmet>

                    <header className="padding-xl">
                        <div className="container-md">
                            <div className="grid yg center text-center">
                                <div className="col-8">
                                    <p className="eyebrow">Tool</p>
                                    <h1>Bruto-netto salaris berekenen</h1>
                                    <p className="streamer center">
                                        Bepaal het netto loon en de loonheffing
                                        via onze gratis omrekentool.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </header>

                    <section className="tools-cards padding-xxl">
                        <div className="container-sm">
                            <div className="grid yg">
                                <div className="col-7">
                                    <div className="title margin-m-bottom">
                                        <h2>Vul het bruto salaris in</h2>
                                        <p>
                                            We berekenen automatisch het{' '}
                                            <strong>netto</strong> loon en de
                                            loonheffing. Er wordt nog geen
                                            rekening gehouden met CAO-regelingen
                                            en met werknemers die boven de
                                            pensioenleeftijd zitten.
                                        </p>
                                    </div>

                                    <form onSubmit={this.handleSubmit}>
                                        <div className="grid yg">
                                            <div className="col-12 no-mar">
                                                <div className="form-group">
                                                    <label>
                                                        Soort werknemer:
                                                    </label>
                                                    <select
                                                        name="employee_type"
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                        className="form-label"
                                                        id="cars"
                                                    >
                                                        <option value="3">
                                                            Werknemer
                                                        </option>
                                                        <option value="4">
                                                            Stagiair
                                                        </option>
                                                        <option value="5">
                                                            DGA
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>

                                            {this.state.employee_type == 3 ? (
                                                <div className="col-12 no-mar">
                                                    <div className="form-group">
                                                        <label>
                                                            Soort dienstverband:
                                                        </label>
                                                        <select
                                                            name="employment_type"
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                            className="form-label"
                                                            id="cars"
                                                        >
                                                            <option value="Fixed">
                                                                Vaste kracht
                                                            </option>
                                                            <option value="Substitute">
                                                                Oproepkracht
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            ) : null}

                                            {this.state.employment_type ==
                                            'Fixed' ? (
                                                <div className="col-6 no-mar">
                                                    <div className="form-group">
                                                        <label>
                                                            Bruto maandsalaris:
                                                        </label>
                                                        <input
                                                            name="bruto_wage"
                                                            type="number"
                                                            min="0"
                                                            step="0.1"
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                            className="form-label"
                                                            id="bruto_wage"
                                                            placeholder="€"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            ) : null}

                                            {this.state.employment_type ==
                                            'Substitute' ? (
                                                <div className="col-6 no-mar">
                                                    <div className="form-group">
                                                        <label>
                                                            Bruto uurloon:
                                                        </label>
                                                        <input
                                                            name="bruto_hourwage"
                                                            type="number"
                                                            min="0"
                                                            step="0.1"
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                            className="form-label"
                                                            id="bruto_wage"
                                                            placeholder="€"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            ) : null}

                                            {this.state.employment_type ==
                                            'Substitute' ? (
                                                <div className="col-6 no-mar">
                                                    <div className="form-group">
                                                        <label>
                                                            Aantal gewerkte
                                                            uren:
                                                        </label>
                                                        <input
                                                            name="hours_worked"
                                                            type="number"
                                                            min="0"
                                                            step="0.1"
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                            className="form-label"
                                                            id="bruto_wage"
                                                            placeholder="u"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            ) : null}

                                            <div className="col-6 no-mar">
                                                <div className="form-group">
                                                    <label>
                                                        Loonheffingskorting:
                                                    </label>
                                                    <select
                                                        name="has_tax_reduction"
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                        className="form-label"
                                                        id="has_tax_reduction"
                                                    >
                                                        <option value="true">
                                                            Ja
                                                        </option>
                                                        <option value="false">
                                                            Nee
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>

                                            {/*<div className="col-12 no-mar">
                        <div className="form-divider"></div>
                        <div className="form-group">
                          <label>Jouw e-mail:</label>
                          <input
                            type="email"
                            disabled={!this.state.virgin}
                            name="email"
                            onChange={this.handleChange}
                            placeholder="Jouw e-mail"
                            required
                          />
                        </div>
                      </div>


                      <div className="col-12">
                        <div
                          className="form-group"
                          style={{ minHeight: 60 + 'px' }}
                        >
                          <div className="flex items-start">
                            <div className="flex items-center h-5">
                              <input
                                disabled={!this.state.virgin}
                                type="checkbox"
                                id="confirmation"
                                name="confirmation"
                                value="true"
                                required
                              />
                            </div>
                            <label
                              htmlFor="confirmation"
                              className="form-label-auto-width"
                            >
                              Ik ga akkoord dat Employes mijn gegevens gebruikt
                              om me te informeren over relevante content,
                              producten en diensten. Je kunt je op ieder moment
                              uitschrijven van deze communicatie.{' '}
                            </label>
                          </div>
                        </div>
                      </div>
                        */}
                                        </div>

                                        <button
                                            type="submit"
                                            className="btn primary margin-m-top lg flex right"
                                        >
                                            Bereken netto salaris
                                        </button>
                                    </form>
                                </div>

                                <div className="col-5">
                                    <div className="tools-cards-single proforma-result card right">
                                        {this.state.isLoading ? (
                                            <div className="proforma-loader-container">
                                                <Loader
                                                    type="Oval"
                                                    color="#e53383"
                                                    height={80}
                                                    width={80}
                                                    timeout={5000}
                                                />
                                            </div>
                                        ) : null}

                                        {!this.state.isLoading ? (
                                            <div className="proforma-result-content">
                                                <p className="eyebrow center margin-xs-top">
                                                    Nettoloon
                                                </p>

                                                <div className="price-container margin-m-bottom">
                                                    <span className="price">
                                                        <h2 className="no-margin padding-xxs-bottom">
                                                            <NumberFormat
                                                                value={
                                                                    this.state
                                                                        .nettoWage
                                                                }
                                                                decimalScale={1}
                                                                fixedDecimalScale={
                                                                    true
                                                                }
                                                                displayType={
                                                                    'text'
                                                                }
                                                                thousandSeparator={
                                                                    false
                                                                }
                                                                allowedDecimalSeparators={
                                                                    ','
                                                                }
                                                                decimalSeparator={
                                                                    ','
                                                                }
                                                            />
                                                            <small>
                                                                / maand
                                                            </small>
                                                        </h2>
                                                    </span>
                                                </div>

                                                <div
                                                    className="proforma-result-stacked-barchart"
                                                    style={{ width: 100 + '%' }}
                                                ></div>
                                                <div
                                                    className="proforma-result-stacked-barchart-2"
                                                    style={{
                                                        width:
                                                            this.state
                                                                .loonheffingPercentage +
                                                            '%',
                                                        left:
                                                            this.state
                                                                .nettoPercentage +
                                                            '%',
                                                    }}
                                                ></div>
                                                <div
                                                    className="proforma-result-stacked-barchart-3"
                                                    style={{
                                                        width:
                                                            this.state
                                                                .zvwPercentage +
                                                            '%',
                                                        left:
                                                            this.state
                                                                .taxableWage +
                                                            '%',
                                                    }}
                                                ></div>

                                                <h6 className="margin-s-top">
                                                    Opbouw van het salaris:
                                                </h6>
                                                <div className="proforma-result-item">
                                                    <span>Bruto loon:</span>
                                                    <span>
                                                        €{' '}
                                                        <NumberFormat
                                                            value={
                                                                this.state
                                                                    .brutoWageResult
                                                            }
                                                            decimalScale={1}
                                                            fixedDecimalScale={
                                                                true
                                                            }
                                                            displayType={'text'}
                                                            thousandSeparator={
                                                                false
                                                            }
                                                            allowedDecimalSeparators={
                                                                ','
                                                            }
                                                            decimalSeparator={
                                                                ','
                                                            }
                                                        />
                                                    </span>
                                                </div>

                                                <div className="proforma-result-item">
                                                    <span className="proforma-input-legend-2" />
                                                    <span
                                                        style={{
                                                            marginLeft:
                                                                12 + 'px',
                                                        }}
                                                    >
                                                        Loonheffing:
                                                    </span>
                                                    <span
                                                        style={{
                                                            marginLeft: 'auto',
                                                        }}
                                                    >
                                                        €{' '}
                                                        <NumberFormat
                                                            value={
                                                                this.state.tax
                                                            }
                                                            decimalScale={1}
                                                            fixedDecimalScale={
                                                                true
                                                            }
                                                            displayType={'text'}
                                                            thousandSeparator={
                                                                false
                                                            }
                                                            allowedDecimalSeparators={
                                                                ','
                                                            }
                                                            decimalSeparator={
                                                                ','
                                                            }
                                                        />
                                                    </span>
                                                </div>

                                                {this.state.employee_type ==
                                                5 ? (
                                                    <div className="proforma-result-item">
                                                        <span className="proforma-input-legend-3" />
                                                        <span
                                                            style={{
                                                                marginLeft:
                                                                    12 + 'px',
                                                            }}
                                                        >
                                                            Bijdrage Zvw:
                                                        </span>
                                                        <span
                                                            style={{
                                                                marginLeft:
                                                                    'auto',
                                                            }}
                                                        >
                                                            €{' '}
                                                            <NumberFormat
                                                                value={
                                                                    this.state
                                                                        .zvw
                                                                }
                                                                decimalScale={1}
                                                                fixedDecimalScale={
                                                                    true
                                                                }
                                                                displayType={
                                                                    'text'
                                                                }
                                                                thousandSeparator={
                                                                    false
                                                                }
                                                                allowedDecimalSeparators={
                                                                    ','
                                                                }
                                                                decimalSeparator={
                                                                    ','
                                                                }
                                                            />
                                                        </span>
                                                    </div>
                                                ) : null}

                                                <div className="proforma-result-item proforma-result-item-last">
                                                    <span className="proforma-input-legend-1" />
                                                    <span
                                                        style={{
                                                            marginLeft:
                                                                12 + 'px',
                                                        }}
                                                    >
                                                        Nettoloon:
                                                    </span>
                                                    <span
                                                        style={{
                                                            marginLeft: 'auto',
                                                        }}
                                                    >
                                                        €{' '}
                                                        <NumberFormat
                                                            value={
                                                                this.state
                                                                    .nettoWage
                                                            }
                                                            decimalScale={1}
                                                            fixedDecimalScale={
                                                                true
                                                            }
                                                            displayType={'text'}
                                                            thousandSeparator={
                                                                false
                                                            }
                                                            allowedDecimalSeparators={
                                                                ','
                                                            }
                                                            decimalSeparator={
                                                                ','
                                                            }
                                                        />
                                                    </span>
                                                </div>

                                                <div class="margin-xs-top">
                                                    <p
                                                        style={{
                                                            fontSize: 12 + 'px',
                                                            lineHeight:
                                                                16 + 'px',
                                                        }}
                                                    >
                                                        * Let op, het gaat om
                                                        een indicatieve
                                                        berekening. Er kunnen
                                                        kunnen geen rechten aan
                                                        ontleend worden.
                                                    </p>
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>

                                    <div className="cta-card right">
                                        <img
                                            src={articleImageBlue}
                                            alt="Backgrounnd image of the card"
                                        />
                                        <p className="eyebrow">
                                            Probeer nu gratis
                                        </p>
                                        <h5 className="margin-s-bottom">
                                            Ervaar het gemak en probeer Employes
                                            gratis uit.
                                        </h5>
                                        <a
                                            href={appUrl + signupRoute}
                                            className="btn primary"
                                        >
                                            Probeer nu
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="vision padding-l-top padding-xl-bottom">
                        <div className="container-sm">
                            <div className="grid yg">
                                <div className="col-12">
                                    <p className="eyebrow">Toelichting</p>
                                    <h2>Bruto-netto berekening</h2>
                                </div>

                                <div className="col-6">
                                    <p className="margin-s-bottom">
                                        Dit is een <strong>bruto-netto</strong>{' '}
                                        calculator die gebruik maakt van de
                                        salarisengine van Employes. Op basis van
                                        de loonheffingstabellen wordt
                                        automatisch de in te houden loonheffing
                                        berekend. Er wordt momenteel nog geen
                                        rekening gehouden met de geboortedatum.
                                    </p>

                                    <h5>Bruto netto berekening werknemers</h5>
                                    <p className="margin-s-bottom">
                                        Je kunt de <strong>bruto-netto</strong>{' '}
                                        tool gebruiken voor zowel vaste krachten
                                        als oproepkrachten. Bij een vaste kracht
                                        voer je het vaste maandsalaris in. Voor
                                        een oproepkracht kun je het uurloon
                                        (inclusief vakantiegeld en
                                        vakantiedagen) invoeren.
                                    </p>

                                    <h5>Bruto netto berekening DGA</h5>
                                    <p className="margin-s-bottom">
                                        Het is ook mogelijk om het netto loon
                                        voor een DGA uit te rekenen. Het
                                        verschil in de berekening ten op zichte
                                        van een gewone werknemer is dat er ook
                                        nog een extra inhouding is voor de Zvw.
                                        Deze premie is in de{' '}
                                        <strong>bruto-netto</strong> berekening
                                        voor gewone werknemers niet van
                                        toepassing omdat die dan door de
                                        werkgever betaald wordt.
                                    </p>
                                </div>
                                <div className="col-6">
                                    <h5>Werkgeverslasten</h5>
                                    <p className="margin-s-bottom">
                                        Deze tool is niet bedoeld om de
                                        werkgeverskosten uit te rekenen. De
                                        loonheffing wordt namelijk betaald door
                                        de werknemer omdat het ingehouden wordt
                                        op zijn of haar loon. Als je de exacte
                                        werkgeverskosten wilt weten, kun je
                                        eventueel een account aanmaken in
                                        Employes. Daar zijn uitgebreide
                                        rapportages in aanwezig zodat je de
                                        kosten kunt bepalen.
                                    </p>

                                    <h5>Volledige loonstrook maken</h5>
                                    <p className="margin-s-bottom">
                                        Wil je graag een volledige loonstrook
                                        maken? Dat kun je doen met het systeem
                                        van Employes. Je kunt het geheel gratis
                                        uitproberen door de gegevens van je
                                        bedrijf in te voeren. Daarna kun je een
                                        proefverloning starten en een loonstrook
                                        maken. Hierop is ook het bruto-netto
                                        deel te zien.
                                    </p>

                                    <p className="seo-link">
                                        Tags:&nbsp;
                                        <Link
                                            className="seo-link"
                                            to="/salarisadministratie-uitbesteden/"
                                        >
                                            Salarisadministratie uitbesteden
                                        </Link>
                                        ,&nbsp;
                                        <Link
                                            className="seo-link"
                                            to="/salarispakket/"
                                        >
                                            Salarispakket
                                        </Link>
                                        ,&nbsp;
                                        <Link
                                            className="seo-link"
                                            to="/salaris-software/"
                                        >
                                            Salaris-software
                                        </Link>
                                        ,&nbsp;
                                        <Link
                                            className="seo-link"
                                            to="/personeelsadministratie/"
                                        >
                                            Personeelsadministratie
                                        </Link>
                                        ,&nbsp;
                                        <Link
                                            className="seo-link"
                                            to="/salarisadministratie-zelf-doen/"
                                        >
                                            Salarisadministratie-zelf-doen
                                        </Link>
                                        ,&nbsp;
                                        <Link
                                            className="seo-link"
                                            to="/salarisverwerking/"
                                        >
                                            Salarisverwerking
                                        </Link>
                                        ,&nbsp;
                                        <Link
                                            className="seo-link"
                                            to="/loon-software/"
                                        >
                                            Loon-software
                                        </Link>
                                        ,&nbsp;
                                        <Link
                                            className="seo-link"
                                            to="/loonadministratie/"
                                        >
                                            Loonadministratie
                                        </Link>
                                        ,&nbsp;
                                    </p>
                                </div>

                                <div className="col-12 margin-m-top">
                                    <h3>
                                        Veel gestelde vragen over de bruto netto
                                        calculator
                                    </h3>
                                </div>
                                <div className="col-6">
                                    <h5>Wat is het bruto salaris?</h5>
                                    <p className="margin-s-bottom">
                                        Het bruto salaris is het overeengekomen
                                        salaris tussen de werkgever en de
                                        werknemer. Dit is het bedrag dat je als
                                        werknemer verdient.
                                    </p>

                                    <h5>Wat is het netto salaris?</h5>
                                    <p className="margin-s-bottom">
                                        Het netto salaris is het bedrag dat je
                                        aan het einde van de maand ontvangt op
                                        je rekening. Dit is het bruto salaris
                                        minus premies en loonbelasting.
                                    </p>

                                    <h5>Hoe wordt de loonheffing berekend?</h5>
                                    <p className="margin-s-bottom">
                                        Om bruto salaris om te rekenen naar
                                        netto salaris moet er rekening gehouden
                                        worden met premies en loonbelasting.
                                        Hoeveel dit is, is afhankelijk van hoe
                                        hoog het salaris is. Daarnaast moet er
                                        bij het berekenen ook rekening gehouden
                                        worden met andere arbeidsvoorwaarden
                                        zoals lunch toeslag,
                                        reiskostenvergoeding, en opbouwen
                                        pensioen.
                                    </p>
                                </div>

                                <div className="col-6">
                                    <h5>
                                        Wat is het verschil tussen bruto en
                                        netto?
                                    </h5>
                                    <p className="margin-s-bottom">
                                        Het verschil tussen het bruto en netto
                                        loon komt doordat er loonheffing wordt
                                        ingehouden. De inhouding hangt onder
                                        andere af van of je de
                                        loonheffingskorting toepast of niet.
                                        Gebruik de bruto netto tool om te
                                        berekeken hoeveel loonheffing er is.
                                    </p>

                                    <h5>Over Employes</h5>
                                    <p className="margin-s-bottom">
                                        Employes is een salarisprogramma waarmee
                                        je zonder dat je salariskennis nodig
                                        hebt, zelf je personeel kunt verlonen.
                                        Er zijn verschillende CAO's ingebouwd en
                                        de aangifte bij de belastingdienst gaat
                                        automatisch. Ben je benieuwd hoe dit
                                        werkt? Bekijk dan de rest van onze
                                        website.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </Layout>
        )
    }
}

export default calculatorPage
